<template>
  <st-side-page class="branch-page">
    <portal to="page-name">Cadastro de empresa</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <template slot="side">
        <div class="branch-image" :style="bgImage"
             :class="{'show-options': showImageOptions}">
          <div class="branch-image-options">
            <template v-if="bgImage">
              <button class="btn btn-icon btn-error tooltip" data-tooltip="Remover logo"
                      @click="removeImage()">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar logo"
                      @click="image.showOptions = true">
                <fa-icon :icon="['fal', 'upload']"/>
                <input type="file" name="image" accept="image/*"
                       @change="loadImage">
              </button>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-12 form-group mt-2">
            <button class="btn btn-block btn-primary" @click="saveBranch"
                    :disabled="saving" :class="{loading: saving}">
              Salvar
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-block" @click="$router.back()">
              Voltar
            </button>
          </div>
        </div>
        <ul class="menu">
          <li class="menu-item" v-for="(menu, i) in menus" :key="i">
            <router-link :to="menu.path">{{ menu.label }}</router-link>
          </li>
        </ul>
      </template>
      <div class="card">
        <div class="card-header">
          <div class="form-group float-right status-toggle">
            <label class="form-switch">
              <input type="checkbox" v-model="form.branch.active">
              <i class="form-icon"/> Registro ativo
            </label>
          </div>
          <div class="card-title">
            Cadastro de empresa
            <span v-if="form.name"> - </span>
            <small class="btn-secondary"> {{ form.name }}</small>
          </div>
        </div>
        <div class="card-body">
          <st-tabs ref="branch-tabs">
            <st-tab id="tab-branch" name="Dados principais">
              <div class="columns">
                <div class="column col-12 mt-2">
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="form.type" value="company"
                           :disabled="!!form.id">
                    <i class="form-icon"/> Pessoa Jurídica
                  </label>
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="form.type" value="person"
                           :disabled="!!form.id">
                    <i class="form-icon"/> Pessoa Física
                  </label>
                </div>
                <template v-if="form.type === 'company'">
                  <div class="column col-3 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.form.identity.value.$error}">
                    <label for="company-cnpj" class="form-label">CNPJ</label>
                    <input type="text" id="company-cnpj" name="company-cnpj"
                           v-model="form.identity.value" :disabled="!!form.id"
                           @blur="$v.form.identity.value.$touch()"
                           key="company-cnpj" @change="loadEntityBranch"
                           class="form-input" autocomplete="nope"
                           placeholder="00.000.000/0000-00" v-mask-cnpj>
                    <template v-if="$v.form.identity.value.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.identity.value.cnpj">CNPJ inválido</div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="column col-3 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.form.identity.value.$error}">
                    <label for="company-cpf" class="form-label">CPF</label>
                    <input type="text" id="company-cpf" name="company-cpf" key="company-cpf"
                           v-model="form.identity.value" :disabled="!!form.id"
                           @blur="$v.form.identity.value.$touch()"
                           class="form-input" autocomplete="nope" @change="loadEntityBranch"
                           placeholder="000.000.000-00" v-mask-cpf>
                    <template v-if="$v.form.identity.value.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.identity.value.cpf">CPF inválido</div>
                    </template>
                  </div>
                </template>
                <div class="column col-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.name.$error}">
                  <label for="company-name" class="form-label">Nome</label>
                  <input type="text" id="company-name" name="company-name"
                         v-model="form.name" class="form-input"
                         @blur="$v.form.name.$touch()"
                         :disabled="!!form.id && form.type === 'person'"
                         placeholder="Nome" autocomplete="nope">
                  <template v-if="$v.form.name.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.name.required">Campo obrigatório</div>
                  </template>
                </div>
                <template v-if="form.type === 'company'">
                  <div class="column col-4 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.form.companyName.$error}">
                    <label for="corporate-name" class="form-label">Razão Social</label>
                    <input type="text" id="corporate-name" name="corporate-name"
                           v-model="form.companyName" class="form-input" :disabled="!!form.id"
                           key="corporate-name" @blur="$v.form.companyName.$touch()"
                           placeholder="Nome" autocomplete="nope">
                    <template v-if="$v.form.companyName.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.companyName.required">Campo obrigatório</div>
                    </template>
                  </div>
                  <div class="column col-1 col-md-6 col-sm-12 form-group">
                    <label for="corporate-name" class="form-label">CNES</label>
                    <input type="text" id="corporate-cnes" name="corporate-cnes"
                           v-model="form.branch.cnes" class="form-input" maxlength="7" v-mask-number
                           key="corporate-cnes" autocomplete="nope" />
                  </div>
                </template>
                <template v-else>
                  <div class="column col-3 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.form.birthDate.$error}">
                    <label for="person-birth-date" class="form-label">Nascimento</label>
                    <input type="text" id="person-birth-date" name="person-birth-date"
                           v-model="form.birthDate" class="form-input" :disabled="!!form.id"
                           @blur="$v.form.birthDate.$touch()" key="person-birth-date"
                           placeholder="dd/mm/aaaa" v-mask-date.br>
                    <template v-if="$v.form.birthDate.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.birthDate.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.birthDate.date">Data inválida</div>
                    </template>
                  </div>
                  <div class="column col-2 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.form.gender.$error}">
                    <label for="person-gender" class="form-label">Sexo</label>
                    <select id="person-gender" name="person-gender"
                            :disabled="!!form.id && !!form.gender"
                            v-model="form.gender" key="person-gender"
                            class="form-select" @change="$v.form.gender.$touch()">
                      <option value="">[Selecione]</option>
                      <option value="female">Feminino</option>
                      <option value="male">Masculino</option>
                    </select>
                    <template v-if="$v.form.gender.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.gender.required">Campo obrigatório</div>
                    </template>
                  </div>
                </template>
              </div>
              <div class="columns">
                <div class="column col-3 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.phone.$error}">
                  <label for="phone" class="form-label">Telefone</label>
                  <input type="text" id="phone" name="phone" v-model="form.phone"
                         class="form-input" @blur="$v.form.phone.$touch()" maxlength="14"
                         placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
                  <template v-if="$v.form.phone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.phone.required">Campo obrigatório</div>
                    <div class="form-input-hint"
                         v-if="!$v.form.phone.phone">Telefone inválido</div>
                  </template>
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.cellphone.$error}">
                  <label for="cellphone" class="form-label">Celular</label>
                  <input type="text" id="cellphone" name="cellphone"
                         v-model="form.cellphone" class="form-input" autocomplete="nope"
                         @blur="$v.form.cellphone.$touch()"
                         placeholder="(00) 0 0000-0000" v-mask-phone.br>
                  <template v-if="$v.form.cellphone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.cellphone.phone">Telefone inválido</div>
                  </template>
                </div>
                <div class="column col-6 col-md-12 col-sm-12 form-group"
                     :class="{'has-error': $v.form.email.$error}">
                  <label for="email" class="form-label">Email</label>
                  <input type="text" id="email" name="email" v-model="form.email"
                         class="form-input" @blur="$v.form.email.$touch()"
                         placeholder="nome@email.com" autocomplete="nope">
                  <template v-if="$v.form.email.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.email.email">Email inválido</div>
                  </template>
                </div>
                <div class="column col-6 col-md-12 col-sm-12 form-group">
                  <label for="site" class="form-label">Site</label>
                  <input type="text" id="site" name="site" v-model="form.site"
                         class="form-input" placeholder="www.google.com.br"
                         autocomplete="nope">
                </div>
              </div>
              <div class="text-gray mt-2">Endereço</div>
              <div class="columns">
                <div class="column col-3 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.address.postalCode.$error}">
                  <label for="address-cep" class="form-label">CEP</label>
                  <div class="input-group">
                    <input type="text" id="address-cep" name="address-cep"
                           class="form-input" v-model="address.postalCode"
                           @blur="$v.address.postalCode.$touch()" placeholder="00.000-000"
                           @change="loadAddress" v-mask-cep autocomplete="nope">
                    <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                            :disabled="loadingAddress" :class="{loading: loadingAddress}">
                      <fa-icon :icon="['fal', 'search']"/>
                    </button>
                  </div>
                  <template v-if="$v.address.postalCode.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.postalCode.cep">CEP inválido</div>
                  </template>
                </div>
                <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
                <div class="column col-9 col-md-12 col-sm-12 form-group"
                     :class="{'has-error': $v.address.addressLine1.$error}">
                  <label for="address-addressLine1" class="form-label">Logradouro</label>
                  <input type="text" id="address-addressLine1" name="address-addressLine1"
                         class="form-input" v-model="address.addressLine1"
                         @blur="$v.address.addressLine1.$touch()"
                         placeholder="Avenida Brasil, 1000" autocomplete="nope">
                  <template v-if="$v.address.addressLine1.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.addressLine1.required">Campo obrigatório</div>
                  </template>
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-addressLine2" class="form-label">Complemento</label>
                  <input type="text" id="address-addressLine2" name="address-addressLine2"
                         class="form-input" v-model="address.addressLine2"
                         placeholder="casa, sobrado, loja..." autocomplete="nope">
                </div>
                <div class="column col-5 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.address.neighborhood.$error}">
                  <label for="address-neighborhood" class="form-label">Bairro</label>
                  <input type="text" id="address-neighborhood" name="address-neighborhood"
                         placeholder="Bairro" v-model="address.neighborhood"
                         @blur="$v.address.neighborhood.$touch()"
                         class="form-input" autocomplete="nope">
                  <template v-if="$v.address.neighborhood.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.neighborhood.required">Campo obrigatório</div>
                  </template>
                </div>
                <div class="column col-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.address.city.$error}">
                  <label for="address-city" class="form-label">Cidade</label>
                  <input type="text" id="address-city" name="address-city"
                         class="form-input" v-model="address.city"
                         @blur="$v.address.city.$touch()"
                         placeholder="Cidade" autocomplete="nope">
                  <template v-if="$v.address.city.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.city.required">Campo obrigatório</div>
                  </template>
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.address.state.$error}">
                  <label for="address-state" class="form-label">Estado</label>
                  <select id="address-state" name="address-state"
                          @blur="$v.address.state.$touch()"
                          class="form-select" v-model="address.state">
                    <option value="">[Selecione]</option>
                    <option v-for="(text, value) in states"
                            :value="value" :key="value">{{ value }}</option>
                  </select>
                  <template v-if="$v.address.state.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.state.required">Campo obrigatório</div>
                  </template>
                </div>
              </div>
              <div class="text-gray">Notas</div>
              <div class="columns">
                <div class="column col-12 form-group">
                  <label for="notes" class="form-label">Observações</label>
                  <textarea id="notes" name="notes" class="form-input" rows="4"
                            v-model="form.notes"/>
                </div>
              </div>
            </st-tab>
            <st-tab id="tab-others" name="Outros">
              <label class="form-label">Código global</label>
              <input type="text"
                     disabled
                     class="form-input"
                     v-model="form.identityId"
              />

              <div class="stenci-title mt-2">
                Permitir acesso apenas aos IPs
              </div>
              <div class="columns form-group">
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label class="form-label">Principal</label>
                  <div class="input-group">
                    <input type="text" v-mask="'000.000.000.000'"
                           class="form-input" placeholder="000.000.000.000">
                    <span class="input-group-addon text-gray">IP</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label class="form-label">Backup 1</label>
                  <div class="input-group">
                    <input type="text" v-mask="'000.000.000.000'"
                           class="form-input" placeholder="000.000.000.000">
                    <span class="input-group-addon text-gray">IP</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label class="form-label">Backup 2</label>
                  <div class="input-group">
                    <input type="text" v-mask="'000.000.000.000'"
                           class="form-input" placeholder="000.000.000.000">
                    <span class="input-group-addon text-gray">IP</span>
                  </div>
                </div>
              </div>

              <!-- Print Settings - General -->
              <div class="divider" />
              <div class="stenci-title mt-2">
                Configuração de impressão (Geral)
              </div>
              <div class="text-info mb-2">
                <small>
                  * Definições de exibição de cabeçalho/rodapé e margens
                  para os impressos do sistema
                  (atestados, declarações, receituários e solicitações)
                </small>
              </div>

              <div class="columns" style="max-width:30rem">
                <div class="column form-group">
                  <label class="form-switch">
                    <input type="checkbox" v-model="printSettings.showHeader">
                    <i class="form-icon"></i>Imprime cabeçalho
                  </label>
                </div>
                <div class="column form-group">
                  <label class="form-switch">
                    <input type="checkbox" v-model="printSettings.showFooter">
                    <i class="form-icon"></i>Imprime rodapé
                  </label>
                </div>
              </div>

              <template v-if="printSettings.showHeader">
                <div class="text-primary text-bold">Logo</div>
                <div class="columns form-group">
                  <div class="column form-group col-3 col-md-12 col-sm-12">
                    <label for="logo-size" class="form-label">Tamanho</label>
                    <div class="input-group">
                      <input type="number" id="logo-size" v-mask="'0000'"
                             v-model="printSettings.image.size"
                             class="form-input" placeholder="0">
                      <span class="input-group-addon text-gray">px</span>
                    </div>
                  </div>
                  <div class="column form-group col-3 col-md-12 col-sm-12">
                    <label for="logo-alignment" class="form-label">Alinhamento</label>
                    <select id="logo-alignment" class="form-select"
                            v-model="printSettings.image.alignment">
                      <option value="center">Centro</option>
                      <option value="right">Direita</option>
                      <option value="left">Esquerda</option>
                    </select>
                  </div>
                </div>
              </template>

              <div class="text-primary text-bold">Margens</div>
              <div class="columns">
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-top" class="form-label">Superior</label>
                  <div class="input-group">
                    <input type="number" id="margin-top" v-mask="'0000'"
                           v-model="printSettings.margins.top"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-bottom" class="form-label">Inferior</label>
                  <div class="input-group">
                    <input type="number" id="margin-bottom" v-mask="'0000'"
                           v-model="printSettings.margins.bottom"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-left" class="form-label">Esquerda</label>
                  <div class="input-group">
                    <input type="number" id="margin-left" v-mask="'0000'"
                           v-model="printSettings.margins.left"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-right" class="form-label">Direita</label>
                  <div class="input-group">
                    <input type="number" id="margin-right" v-mask="'0000'"
                           v-model="printSettings.margins.right"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
              </div>

              <!-- Print Settings - Medical Report -->
              <div class="divider" />
              <div class="stenci-title mt-2">
                Configuração de impressão (Laudos)
              </div>
              <div class="text-info mb-2">
                <small>
                  * Definições de exibição de cabeçalho/rodapé e margens
                  para a impressão dos laudos
                </small>
              </div>

              <div class="columns" style="max-width:30rem">
                <div class="column form-group">
                  <label class="form-switch">
                    <input type="checkbox" v-model="printSettings.medicalReport.showHeader">
                    <i class="form-icon"></i>Imprime cabeçalho
                  </label>
                </div>
                <div class="column form-group">
                  <label class="form-switch">
                    <input type="checkbox" v-model="printSettings.medicalReport.showFooter">
                    <i class="form-icon"></i>Imprime rodapé
                  </label>
                </div>
              </div>

              <template v-if="printSettings.medicalReport.showHeader">
                <div class="text-primary text-bold">Logo</div>
                <div class="columns form-group">
                  <div class="column form-group col-3 col-md-12 col-sm-12">
                    <label for="logo-size" class="form-label">Tamanho</label>
                    <div class="input-group">
                      <input type="number" id="logo-size" v-mask="'0000'"
                             v-model="printSettings.medicalReport.image.size"
                             class="form-input" placeholder="0">
                      <span class="input-group-addon text-gray">px</span>
                    </div>
                  </div>
                  <div class="column form-group col-3 col-md-12 col-sm-12">
                    <label for="logo-alignment" class="form-label">Alinhamento</label>
                    <select id="logo-alignment" class="form-select"
                            v-model="printSettings.medicalReport.image.alignment">
                      <option value="center">Centro</option>
                      <option value="right">Direita</option>
                      <option value="left">Esquerda</option>
                    </select>
                  </div>
                </div>
              </template>

              <div class="text-primary text-bold">Margens</div>
              <div class="columns">
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-top" class="form-label">Superior</label>
                  <div class="input-group">
                    <input type="number" id="margin-top" v-mask="'0000'"
                           v-model="printSettings.medicalReport.margins.top"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-bottom" class="form-label">Inferior</label>
                  <div class="input-group">
                    <input type="number" id="margin-bottom" v-mask="'0000'"
                           v-model="printSettings.medicalReport.margins.bottom"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-left" class="form-label">Esquerda</label>
                  <div class="input-group">
                    <input type="number" id="margin-left" v-mask="'0000'"
                           v-model="printSettings.medicalReport.margins.left"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
                <div class="column form-group col-3 col-md-12 col-sm-12">
                  <label for="margin-right" class="form-label">Direita</label>
                  <div class="input-group">
                    <input type="number" id="margin-right" v-mask="'0000'"
                           v-model="printSettings.medicalReport.margins.right"
                           class="form-input" placeholder="0">
                    <span class="input-group-addon text-gray">mm</span>
                  </div>
                </div>
              </div>

              <!-- Medical Reports Result -->
              <div class="divider" />
              <div class="stenci-title form-group mt-2">Entrega de exames</div>
              <div class="columns">
                <div class="column form-group col-3 col-sm-12">
                  <label for="pacs-prefix" class="form-label">Protocolo prefixo</label>
                  <input type="text" id="pacs-prefix" placeholder="STENCI"
                         class="form-input" v-model="printSettings.pacsPrefix">
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label">Observações</label>
                  <st-editor id="pacs-notes"
                             v-model="printSettings.pacsNotes"></st-editor>
                </div>
              </div>
            </st-tab>
          </st-tabs>
        </div>
      </div>
    </template>
  </st-side-page>
</template>

<script>
import entityMixin from 'src/mixins/entity';
import { required } from 'vuelidate/src/validators';
import { cep, cpf, cnpj } from 'src/data/validators';
import { mergeFrom } from 'src/helpers/object';

export default {
  mixins: [entityMixin],
  data() {
    return {
      type: 'branch',
      path: '/branches',
      form: {},
      menus: [],
      image: this.imageFormBlank(),
      printSettings: this.blankSettingsForm(),
    };
  },
  created() {
    this.form = this.blankForm();
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.form.type = 'company';

    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load(this.form.id, this.path);
      await this.loadPrintSettings();
    }
  },
  validations() {
    return this.entityValidationRules((rules) => {
      const localRules = rules;
      localRules.address = {
        postalCode: { cep },
        addressLine1: { required },
        neighborhood: { required },
        city: { required },
        state: { required },
      };
      if (this.form.type === 'person') {
        localRules.form.identity = {
          value: { required, cpf },
        };
      } else {
        localRules.form.identity = {
          value: { required, cnpj },
        };
      }

      localRules.place = {
        form: {
          name: { required },
        },
      };

      return localRules;
    });
  },
  computed: {
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
  },
  methods: {
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    loadPrintSettings() {
      return this.$http
        .get('/print-settings')
        .then(({ data }) => {
          if (data) {
            this.printSettings = mergeFrom(this.blankSettingsForm(), {
              showHeader: data.showHeader,
              showFooter: data.showFooter,
              margins: data.margins,
              pacsPrefix: data.pacsPrefix,
              pacsNotes: data.pacsNotes || null,
              image: {
                alignment: data.image ? data.image.alignment : 'left',
                size: data.image ? data.image.size : 50,
              },
              medicalReport: data.medicalReport,
            });
          }
        })
        .catch(() => {});
    },
    removeImage() {
      this.image = this.imageFormBlank();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    loadEntityBranch() {
      this.loadEntityByIdentity(this.path);
    },
    saveBranch() {
      this.$v.form.$touch();
      this.$v.address.$touch();
      if (this.$v.form.$error
        || this.$v.address.$error) {
        return;
      }
      this.address.type = 'business';

      this.save(this.path)
        .then((data) => {
          if (/create$/.test(this.$route.path)) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          if (this.image.file) {
            const formData = new FormData();
            formData.append('file', this.image.file);
            this.$http.post(`/entities/${data.id}/images`, formData)
              .then(() => {
                this.image.file = null;
              })
              .catch(() => {});
          }
          this.savePrintSettings();
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    savePrintSettings() {
      const postData = this.clone(this.printSettings);

      postData.marginTop = postData.margins.top;
      postData.marginBottom = postData.margins.bottom;
      postData.marginLeft = postData.margins.left;
      postData.marginRight = postData.margins.right;
      postData.imageAlignment = postData.image.alignment;
      postData.imageSize = postData.image.size;
      delete postData.margins;
      delete postData.image;

      postData.medicalReport.marginTop = postData.medicalReport.margins.top;
      postData.medicalReport.marginBottom = postData.medicalReport.margins.bottom;
      postData.medicalReport.marginLeft = postData.medicalReport.margins.left;
      postData.medicalReport.marginRight = postData.medicalReport.margins.right;
      postData.medicalReport.imageAlignment = postData.medicalReport.image.alignment;
      postData.medicalReport.imageSize = postData.medicalReport.image.size;
      delete postData.medicalReport.margins;
      delete postData.medicalReport.image;

      return this.$http.post('/print-settings', postData)
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    blankSettingsForm() {
      const image = {
        alignment: 'left',
        size: 50,
      };
      const margins = {
        top: '60',
        bottom: '60',
        left: '40',
        right: '40',
      };

      return {
        showHeader: true,
        showFooter: true,
        pacsPrefix: '',
        pacsNotes: '',
        image,
        margins,
        medicalReport: {
          showHeader: false,
          showFooter: false,
          image: this.clone(image),
          margins: this.clone(margins),
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.branch-page {
  &.side-page .side-page-side {
    flex: 0 0 10.1rem;
    dd {
      margin-top: 0;
    }
  }
  .branch-image {
    margin-top: $layout-spacing;
    background: $gray-color-light center no-repeat;
    background-size: cover;
    border-radius: $border-radius;
    height: 9rem;
    width: 9rem;
    &:not(.show-options):hover {
      .branch-image-options {
        opacity: 1;
        background-color: rgba($gray-color-light, .97);
      }
    }
    &.show-options {
      .branch-image-options {
        opacity: 1;
      }
    }
  }
  .branch-image-options {
    align-items: center;
    background-color: $gray-color-light;
    border-radius: $border-radius;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    transition: all .3s ease;
    .btn {
      margin: calc($layout-spacing / 2);
      position: relative;
      input {
        bottom: 0;
        display: block;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $zindex-0;
      }
    }
  }
  .panel {
    background-color: #fff;
  }
  #pacs-notes {
    .ck.ck-content.ck-editor__editable {
      min-height: 5rem;
    }
  }
}
</style>

<template>
  <div class="page-container branches-page">
    <portal to="page-name">Empresas</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Empresas</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <table class="table table-striped table-hover" v-else>
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th class="hide-sm">CNPJ</th>
            <th class="hide-sm hide-md">Telefone</th>
            <th class="hide-sm hide-md">Ativo</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td><router-link :to="editLink(item)">{{ item.name }}</router-link></td>
            <td class="hide-sm">
              <span v-if="item.type === 'person'">
                {{ item.identity ? item.identity.value : '' | cpf }}
              </span>
              <span v-else>
                {{ item.identity ? item.identity.value : '' | cnpj }}
              </span>
            </td>
            <td class="hide-sm hide-md">{{ item.phone ? item.phone : item.cellphone | phone }}</td>
            <td class="hide-sm hide-md">
              {{ item.branch.active ? 'Sim' : 'Não' }}
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      path: '/branches',
      filter: {
        search: '',
      },
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .branches-page {
    .btn-fixed-width {
      font-size: $font-size-lg;
      height: 2.2rem;
      margin: $layout-spacing-lg 0;
      width: 11rem;
    }
  }
</style>
